import React, { Component } from 'react'
import './index.css'
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'

export default class Notice extends Component {
	state = {
		isShowNav: false,
		navList: [
			{ name: '首页', id: 0 },
			{ name: '业务', id: 1 },
			{ name: '公告', id: 2 },
			{ name: '诚聘英才', id: 3 },
			{ name: '关于我们', id: 4 }
		],
		navActive: 2
	}
	showNavList = () => {
		this.setState({ isShowNav: !this.state.isShowNav })
	}
	sleectNav = index => {
		this.setState({ navActive: index })
		switch (index) {
			case 0:
				//语句
				this.props.history.push(`/`)
				break
			case 1:
				this.props.history.push(`/business`)
				//语句
				break
			case 2:
				this.props.history.push(`/notice`)
				//语句
				break
			case 3:
				this.props.history.push(`/invite`)
				//语句
				break
			case 4:
				this.props.history.push(`/user`)
				//语句
				break
			default:
				//语句
				this.props.history.push(`/`)
		}
	}
	render() {
		return (
			<div className='m_box'>
				<div className='m_head'>
					<img
						className='m_logo'
						src={require('../../assets/images/m_icon_logo.png')}
					></img>
					<img
						className='m_nav_icon'
						src={require('../../assets/images/icon_gengduo.png')}
						onClick={() => this.showNavList()}
					></img>
				</div>
				{/* 下拉菜单 */}
				{this.state.isShowNav ? (
					<div className='m_nav'>
						{this.state.navList.map((item, index) => {
							return (
								<div
									className={
										this.state.navActive == item.id
											? 'm_nav_item_active'
											: 'm_nav_item'
									}
									key={index}
									onClick={() => this.sleectNav(index)}
								>
									{item.name}
								</div>
							)
						})}
					</div>
				) : null}
				<div className='content'>
					<div className='content_box'>
						<div className='caption'>
							1、【品牌升级】米虫2.0全【新】来袭
						</div>
						<Player
							className='video_box'
							playsInline
							src={require('../../assets/images/mobile_img/video_1.mp4')}
						/>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_1.png')}
						></img>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_2.png')}
						></img>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_3.png')}
						></img>
						<div className='caption'>2、米虫2022产业链规划发布</div>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_4.png')}
						></img>
						<div className='caption'>
							3、【未来 触手可及】2022米虫产业链分享大会圆满召开
						</div>
						<div>
							光之春颂 歌唱破土的希望
							米虫在2022年3月8日召开了2022年度米虫产业链规划分享大会
							本次会议主题为“未来 触手可及”
						</div>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_5.png')}
						></img>
						<div>
							时间匆匆而过，自中国米虫（武汉公司）去年11月份正式成立以来，到现在已经运营快半年了，米虫的小伙伴们也伴随公司一起成长，奋斗了100多天了。自米虫团队组建以来，不管哪个部门、哪位员工都表现出了肯吃苦、肯付出、肯学习的精神。在公司领导的带领下，在所有米虫小伙伴的共同努力下，公司流程在不断地完善、米虫品牌影响力在不断扩大、同时我们第一季度的业绩也超额完成。米虫的每一个成就都离不开米虫人的辛苦工作。
							本次“未来
							触手可及”2022米虫产业链分享大会旨在通过工作回顾、工作部署及2022年米虫规划安排，来明确各部门接下来的工作方向，鼓舞大家的士气，让大家能有更加十足的信心更加激情高涨的情绪投入到接下来的工作中。
						</div>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_6.png')}
						></img>
						<div>
							米虫虽然创立时间很短，是一个全新的团队，做的也是一个全新的项目，米虫之所以能快速成长和壮大，是因为我们有家人般的情怀，正如米虫公司的管理理念一样——“以人为本
							科技向上”。
							米虫坚持二位一体的管理理念，从员工个人价值出发，科技成就为导向，努力为每一位员工创造更丰厚的物质保障、更广阔的发展空间、更丰富的精神文化世界。不断完善员工培养体系和职业发展通道，使员工与企业同步成长，充分尊重和信任员工，不断引导和鼓励，帮助所有米虫人成就并分享喜悦。
							米虫的进步和发展离不开每一位米虫员工凝聚而成的向心力，更离不开永远站在最前方指引我们前景的领导人。
							米虫CEO桐总在本次大会上，就中国米虫公司背景及元宇宙相关知识点及米虫产业链规划做了详解，旨在让每一位员工能够更加了解元宇宙知识及公司产业链规划。
						</div>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_7.jpg')}
						></img>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_8.jpg')}
						></img>
						<div>
							桐总表示米虫现阶段的主要工作是在米虫app的开发及市场流量获取的基础上，继续进行多场景搭建。提高产品运行流畅度及市场认可度，并逐步向规划的米虫元宇宙生态圈靠拢，各部门做到配合好市场团队及技术团队的支持工作。
							除此之外，米虫要在具体的产品开发和场景创建中保证产品质量及市场反馈。
							同时，桐总也给我们分享了她自己的人生奋斗经历和职场规划感悟。
							所有米虫小伙伴们认真聆听，积极做笔记，现场更是掌声雷动，气氛轻松而愉快。
						</div>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_9.jpg')}
						></img>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_10.jpg')}
						></img>
						<div>
							会议最后，桐总号召，全公司上下要有社会责任感及企业使命感，保持创业心态，对自己的人生负责，主动作为、迎难而上，一如既往的保持艰苦奋斗的创业精神。同时也希望大家增强自主性，只要不放弃，内心依然炙热，现在的努力，必将得到回报。
						</div>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_11.png')}
						></img>
						<img
							className='content_img'
							src={require('../../assets/images/mobile_img/notice_12.png')}
						></img>
						<div>
							就像桐总所说的一样，现在我们所要做的事情
							是一个领跑于90%其他互联网企业的元宇宙概念生态圈
							我们要打造的也不是一时的爆款互联网产品
							而是一个全新的元宇宙场景化产业链
							只要有米虫小伙伴们的努力，我们携手共进
							保持充足的干劲和激情，提高执行力
							我们终将把不可能变为可能 未来 触手可及，
							我们的航线终将驶向我们想要到达的 星辰大海~
						</div>
						<Player
							className='video_box'
							playsInline
							src={require('../../assets/images/mobile_img/video_2.mp4')}
						/>
					</div>
					<div className='footer'>
						<div className='about_user'>关于我们</div>
						<div className='line'></div>
						<div className='icon_list'>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_two.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_one.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_three.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_four.png')}
							></img>
						</div>
						<div className='footer_nav'>
							<div className='footer_nav_item'>关注我们</div>
							<div className='footer_nav_item'>业务</div>
							<div className='footer_nav_item'>公告</div>
							<div className='footer_nav_item'>诚聘英才</div>
						</div>
						<div className='footer_des'>
							Copyright© 2021-2022 杭州影力网络科技有限公司
							版权所有{' '}
							<a
								style={{ marginLeft: '5px' }}
								href='https://beian.miit.gov.cn/'
								target='_blank'
							>
								津ICP备2020009338号-2
							</a>
							<br />
							<span style={{ marginLeft: '5px' }}>
								投诉邮箱: 13044877857@163.com
							</span>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
