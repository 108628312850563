import React, { Component } from 'react'
import './index.css'

export default class Notice extends Component {
	state = {
		isShowNav: false,
		navList: [
			{ name: '首页', id: 0 },
			{ name: '业务', id: 1 },
			{ name: '公告', id: 2 },
			{ name: '诚聘英才', id: 3 },
			{ name: '关于我们', id: 4 }
		],
		navActive: 3
	}
	showNavList = () => {
		this.setState({ isShowNav: !this.state.isShowNav })
	}
	sleectNav = index => {
		this.setState({ navActive: index })
		switch (index) {
			case 0:
				//语句
				this.props.history.push(`/`)
				break
			case 1:
				this.props.history.push(`/business`)
				//语句
				break
			case 2:
				this.props.history.push(`/notice`)
				//语句
				break
			case 3:
				this.props.history.push(`/invite`)
				//语句
				break
			case 4:
				this.props.history.push(`/user`)
				//语句
				break
			default:
				//语句
				this.props.history.push(`/`)
		}
	}
	render() {
		return (
			<div className='m_box'>
				<div className='m_head'>
					<img
						className='m_logo'
						src={require('../../assets/images/m_icon_logo.png')}
					></img>
					<img
						className='m_nav_icon'
						src={require('../../assets/images/icon_gengduo.png')}
						onClick={() => this.showNavList()}
					></img>
				</div>
				{/* 下拉菜单 */}
				{this.state.isShowNav ? (
					<div className='m_nav'>
						{this.state.navList.map((item, index) => {
							return (
								<div
									className={
										this.state.navActive == item.id
											? 'm_nav_item_active'
											: 'm_nav_item'
									}
									key={index}
									onClick={() => this.sleectNav(index)}
								>
									{item.name}
								</div>
							)
						})}
					</div>
				) : null}
				<div className='content'>
					<div className='content_box'>
						<div>
							大家好，我们是靠谱又有趣的 <br />
							——米虫团队 <br />
							2022招聘季，废话不多说， <br />
							告诉大家：我们招人啦！ <br />
							<br />
						</div>
						<div>
							作为布局元宇宙领域的新型互联网公司， <br />
							我们一直在努力推动各个项目的研发 <br />
							及商业模式的调整。 <br />
							米虫元宇宙希望能通过自己的努力及公司项目的开发，构建一个属于{' '}
							<br />
							我们自己的元宇宙生态链。
							<br />
							米虫互联（武汉）公司自2021年11月成立以来，构建了一个以90后为主的年轻团队，
							<br />
							不论是从技术研发、项目运营还是市场拓展，
							<br />
							每个部门及团队都激情满满，
							<br />
							共同推动项目发展。
							<br />
							至今，米虫电商/米虫plus产品
							<br />
							已上线试运营2周时间，
							<br />
							后台总订单量5100单，
							<br />
							投诉率低于0.1%，
							<br />
							客户及供应商好评度高达99%。
							<br />
							并于3月正式上线了
							<br />
							属于我们自己的米虫APP，
							<br />
							其中承载的功能不仅仅局限于现有的米虫电商、米虫视频，
							<br />
							还会加入米虫元宇宙生态版块中的社交属性、游戏属性及金融中心等。
							<br />
							我们有好多好多想去开拓的项目、领域、形式......
							<br />
							我们需要好多好多具有专业能力、想法、创意的小伙伴们加入我们的大家庭来一起创造我们的星球！
							<br />
							如果你对我们感兴趣，有理想有抱负，请您仔细阅读下面的招聘内容，快快把简历发给我们吧~
							<br />
						</div>
						<div>
							<div className='bold_text'>
								1、高级Java开发工程师 【岗位职责】
							</div>
							<div>
								参与业务线的技术设计、开发、测试工作；
								<br />
								参与复杂业务系统的架构设计和核心代码的编写；
								<br />
								负责业务需求把控、结构设计、功能开发、文档编写；
								<br />
								协助、指导初级软件工程师解决关键问题；
								<br />
								上级领导安排的其它任务。
								<br />
							</div>
							<div className='bold_text'>【任职要求】</div>
							<div>
								本科及以上学历，计算机相关专业；
								<br />
								5年以上Java
								实际开发经验，具备短视频或电商项目经验；带过项目团队的优先；
								<br />
								熟悉Spring Boot、Spring Cloud开发框架，具备
								Spring
								Cloud和Web中、后台管理系统实际项目开发经验；
								<br />
								具有扎实的java功底，熟悉jvm、web开发、缓存、多线程、分布式架构、分布式事务、消息中间件等技术，深入理解面向对象的编程，了解常见的设计模式，熟悉微服务的架构模式，熟悉敏捷开发的开发模式；
								<br />
								熟悉Linux系统的安装部署，熟悉Linux常用命令，熟练编写Shell
								脚本；
								<br />
								具有创新思维，主动性和责任感强，问题解决能力强。
								<br />
							</div>
							<div className='bold_text'>
								2、产品经理 【岗位职责】
							</div>
							<div>
								负责公司视频，电商项目核心功能的产品规划、设计，驱动产品生命周期完成;
								<br />
								提出和确认产品需求，编写产品PRD文档、绘制产品原型、设计业务流程和数据流程，组织产品评审，并输入到研发部门；
								<br />
								有较强的C端用户思维，对客户和市场有一定的洞察力，具备运用数据对产品和业务做端到端的分析能力；
								<br />
								了解主流视频产品、电商、直播及其用户的心理，结合业务情况，设计对应的功能;
								<br />
								对产品和用户数据进行分析，提升新增、留存、转化等核心数据指标;
								<br />
								定期开展竞品研究跟踪分析、用户访谈，不断优化用户体验，提升平台竞争力;
								<br />
								负责产品的设计和推进工作，对开发、测试、上线等整个过程负责，并对最终产品上线结果负责。
								<br />
							</div>
							<div className='bold_text'>【任职要求】</div>
							<div>
								本科及以上学历，计算机相关专业优先；
								<br />
								3年以上互联网产品设计经验，所负责的项目正式上线运营，并取得良好效果;
								<br />
								熟悉主流的视频类及电商产品，对用户的心理有深入理解，有0到1产品经验;
								<br />
								善于通过数据分析、用户访谈发现问题，并驱动产品不断迭代优化；
								<br />
								较强的产品Owner意识，优秀的沟通协作和项目管理能力，能在复杂的环境下灵活调整;
								<br />
								逻辑能力强，对复杂业务有较强的梳理能力与优化能力。
								<br />
							</div>
							<div className='bold_text'>
								3、高级用户体验设计师 【岗位职责】
							</div>
							<div>
								负责B端,C端产品的视觉设计(含APP/WEB);
								<br />
								和产品交互一起参与产品前期调研，负责构思创意、灵活提供视觉解决方案；
								<br />
								负责产品宣传和品牌影响力构建；
								<br />
								时刻分析监控流行产品设计趋势，研究目标用户行为持续优化现有产品。
								<br />
							</div>
							<div className='bold_text'>【任职要求】</div>
							<div>
								本科及以上学历，计算机相关专业优先；
								<br />
								熟练使用Figma，对设计流程和设计体系有深入思考和理解；
								<br />
								3年以上的互联网或移动互联网设计经验，熟悉Web、iOS、Android设计规则，具备成功案例者优先；
								<br />
								具备3D（如Cinema4D）表现能力、视频制作能力（如AE、PR);
								<br />
								对前沿设计探索感兴趣，自我驱动能力强，能用设计效果准确表达想法；
								<br />
								能够深入一线场景，立足设计为产品、业务赋能。
								<br />
							</div>
							<div className='bold_text'>
								4、电商选品专员 【岗位职责】
							</div>
							<div>
								根据各电商平台选品策略和用户分析，进行选品分析;
								<br />
								根据公司业务方向和优势，负责对外沟通合作及商家拓展，优质产品的引进、评估等;
								<br />
								根据不同消费者的粉丝画像挑选出适合的产品，具备优秀的选品能力;
								<br />
								根据销售数据分析市场变化，及时调整选款方向；
								<br />
								根据定位需求建立具有竞争力的商家库及商品库；
								<br />
								深入挖掘业务及用户需求，分析各种不同购物网站、商城的相关数据及其动态，寻找热销爆款产品（或者品牌）。
								<br />
							</div>
							<div className='bold_text'>【任职要求】</div>
							<div>
								大专及以上学历，专业不限；
								<br />
								两年以上相关工作经验；
								<br />
								具备优秀的沟通协调能力、谈判能力、抗压能力、目标感强;
								<br />
								具有极强的数据敏感度、逻辑思维能力强、自驱能力强;
								<br />
								如果有产品资源更佳，如：抖音直播选品，淘宝，京东等；
								<br />
								工作态度积极，责任心强。
								<br />
							</div>
							<div className='bold_text'>
								5、电商运营 【岗位职责】
							</div>
							<div>
								制定及执行运营项目的战略规划及日常电商运营相关的制度体系；
								<br />
								负责公司电商平台运营，通过策划各类活动，结合各种互联网资源进行有效的广告宣传；
								<br />
								负责平台产品促销活动、关联销售等的策划，流量引入，提升店铺转化率
								；<br />
								负责公司电商产品促活、拉新、复购等，完成团队业绩销售目标； 
								<br />
								负责分析市场需求及市场同类产品情况，制定相应的宣传竞争策略；
								<br />
								负责电商产品营销活动的数据统计、分析。
								<br />
							</div>
							<div className='bold_text'>【任职要求】</div>
							<div>
								大专及以上学历，2年及以上互联网从业、电商运营经验，有国内大型电商平台运营经验者优先;
								<br />
								熟悉电商平台的运作规则，各种平台内推广工具和活动；
								<br />
								全面配合电商经理工作、完成公司业绩、利润及团建指标。
								<br />
								业务拓展能力强、思维清晰、有责任心、良好的
								心理素质;
								<br />
								具有敏锐的市场洞察力、策略能力和较强的开拓精神。
								<br />
							</div>
							<div className='bold_text'>
								6、社群运营 【岗位职责】
							</div>
							<div>
								社群运营方向：负责公司社群营销（微信、微博、社区群、粉丝群）的组建，管理，维护，挖掘潜在用户群体，用户需求的运营机制改进，整合内外部各种资源，优化运营手段，提升用户活跃度和粘度，增加信任，延续平台口碑；
								<br />
								用户管理运营方向：挖掘核心用户，维系社群活跃度，制造感兴趣的话题，创造互动氛围，深挖用户需求，激发用户参与度，提升影响力；
								<br />
								产品及用户活动策划方向：产品优质内容的分享和沉淀、达人会员的转换和裂变，监督各项运营数据，对相关的数据进行分析，并提出社群管理改进方案；
								<br />
								售后支持方向：产品售后支持工作，社群内的答疑和达人会员升级的培训及维护事宜；
								<br />
								探索社群用户裂变式增长的方案。
							</div>
							<div className='bold_text'>【任职要求】</div>
							<div>
								大专及以上学历，一年以上工作经验；
								<br />
								具备一定的新媒体运营、网络推广、渠道销售等工作经验；
								<br />
								有较强的学习、沟通表达能力，有良好的团队合作意识和承压能力。
								<br />
								<br />
								我们可以很有底气的说，米虫
								<br />
								是一个充满机会与挑战的大舞台
								<br />
								这里有一群能和你并肩前进的靠谱伙伴
								<br />
								有包容开放、鼓励创新的团队文化
								<br />
								更有清晰公平的成长和晋升通道
								<br />
								在米虫，只要你有能力、有想法
								<br />
								有坚持，就有机会
								<br />
								实现你想做的任何事。
								<br />
							</div>
						</div>
					</div>
					<div className='footer'>
						<div className='about_user'>关于我们</div>
						<div className='line'></div>
						<div className='icon_list'>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_two.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_one.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_three.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_four.png')}
							></img>
						</div>
						<div className='footer_nav'>
							<div className='footer_nav_item'>关注我们</div>
							<div className='footer_nav_item'>业务</div>
							<div className='footer_nav_item'>公告</div>
							<div className='footer_nav_item'>诚聘英才</div>
						</div>
						<div className='footer_des'>
							Copyright© 2021-2022 杭州影力网络科技有限公司
							版权所有{' '}
							<a
								style={{ marginLeft: '5px' }}
								href='https://beian.miit.gov.cn/'
								target='_blank'
							>
								津ICP备2020009338号-2
							</a>
							<br />
							<span style={{ marginLeft: '5px' }}>
								投诉邮箱: 13044877857@163.com
							</span>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
