import React, { Component } from 'react'
import './index.css'

export default class Notice extends Component {
	state = {
		isShowNav: false,
		navList: [
			{ name: '首页', id: 0 },
			{ name: '业务', id: 1 },
			{ name: '公告', id: 2 },
			{ name: '诚聘英才', id: 3 },
			{ name: '关于我们', id: 4 }
		],
		navActive: 3
	}
	showNavList = () => {
		this.setState({ isShowNav: !this.state.isShowNav })
	}
	sleectNav = index => {
		this.setState({ navActive: index })
		switch (index) {
			case 0:
				//语句
				this.props.history.push(`/`)
				break
			case 1:
				this.props.history.push(`/business`)
				//语句
				break
			case 2:
				this.props.history.push(`/notice`)
				//语句
				break
			case 3:
				this.props.history.push(`/invite`)
				//语句
				break
			case 4:
				this.props.history.push(`/user`)
				//语句
				break
			default:
				//语句
				this.props.history.push(`/`)
		}
	}
	render() {
		return (
			<div className='m_box'>
				<div className='m_head'>
					<img
						className='m_logo'
						src={require('../../assets/images/m_icon_logo.png')}
					></img>
					<img
						className='m_nav_icon'
						src={require('../../assets/images/icon_gengduo.png')}
						onClick={() => this.showNavList()}
					></img>
				</div>
				{/* 下拉菜单 */}
				{this.state.isShowNav ? (
					<div className='m_nav'>
						{this.state.navList.map((item, index) => {
							return (
								<div
									className={
										this.state.navActive == item.id
											? 'm_nav_item_active'
											: 'm_nav_item'
									}
									key={index}
									onClick={() => this.sleectNav(index)}
								>
									{item.name}
								</div>
							)
						})}
					</div>
				) : null}
				<div className='content'>
					<div className='content_box'>
						<div>
							<div className='bold_text'>一、关于我们</div>
							<div className='bold_text'>（一）企业简介</div>
							<div>
								中国米虫控股集团,创立于英国，英文名称“Nomad
								Tech”，是马来西亚互联网管理服务行业中排名前五的公司。于2019年在香港联交所上市（股票代码：08645.HK）。本集团主要经营：可交互视频、电商3.0、网络安全、元宇宙基础建设、游戏研发、证券基金、地产开发等项目，公司专注于新型科技技术的创作和研发，和国内外科研机构有多项技术合作，并在全球建立多个科研实验室，在网络安全、Mateverse应用技术、AI智能、第四代编程语言领域拥有多项技术突破，是一家非常有技术实力的高科技企业。
							</div>
							<div className='bold_text'>（二）企业文化</div>
							<div>
								企业使命：实现跨越实体和数字世界的全新变革
								<br />
								企业愿景：成为元宇宙头部互联网公司
								<br />
								管理理念：以人为本，科技向上
								<br />
								米虫坚持二位一体的管理理念，从员工个人价值出发，科技成就为导向，努力为每一位员工创造更丰厚的物质保障、更广阔的发展空间、更丰富的精神文化世界。完善员工培养体系和职业发展通道，使员工与企业同步成长。充分尊重和信任员工，不断引导和鼓励，帮助其获得成就并分享喜悦。
								<br />
								经营理念：
								<br />
								创造用户长远价值
								<br />
								价值观：
								<br />
								To make the impossible
								possible（将不可能变为可能）
								<br />
								我们必须要相信那些不可能的事情，那些看起来不太可能成为我们所使用的东西，将来肯定会为我们所用。
								<br />
								积极进取
								协作开放：勇于突破有担当，开放协同一起成长{' '}
								<br />
								不忘初心
								多元兼容：有韧性及自驱力，人人参与友好兼容
								<br />
								注解：
								<br />
								①　M世代（Meta
								Genenration），即生活在元宇宙这代人，大约出生于95后到2010年，这代人伴随着互联网一起成长，受到互联网、即时通讯、短讯、MP3、智能手机和平板电脑等科技产物影响很大。他们通常不畏权威、追求社交认同与自我实现、愿意为只是以及喜欢的一切付费，他们是元宇宙世界的居民，他们与智能手机一起成长，智能手机的发展史就是他们的成长史。
								<br />
								②　GAMEFI（游戏化金融），既将去中心化金融产品以游戏方式呈现，在游戏中引入DEFI以及NFT质押，并将玩家的资产转化成为游戏内资产或代币，通过流通性挖矿提升玩家收益。
							</div>
							<br />
						</div>
					</div>
					<div className='footer'>
						<div className='about_user'>关于我们</div>
						<div className='line'></div>
						<div className='icon_list'>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_two.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_one.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_three.png')}
							></img>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_four.png')}
							></img>
						</div>
						<div className='footer_nav'>
							<div className='footer_nav_item'>关注我们</div>
							<div className='footer_nav_item'>业务</div>
							<div className='footer_nav_item'>公告</div>
							<div className='footer_nav_item'>诚聘英才</div>
						</div>
						<div className='footer_des'>
							Copyright© 2021-2022 杭州影力网络科技有限公司
							版权所有{' '}
							<a
								style={{ marginLeft: '5px' }}
								href='https://beian.miit.gov.cn/'
								target='_blank'
							>
								津ICP备2020009338号-2
							</a>
							<br />
							<span style={{ marginLeft: '5px' }}>
								投诉邮箱: 13044877857@163.com
							</span>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
