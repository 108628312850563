//引入connect用于连接UI组件与redux
import { connect } from 'react-redux'
import React, { Component } from 'react';
import './assets/css/App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import routes from './router';
import intl from 'react-intl-universal';
import { emit } from './locales/emit'
const locales = {
  "en-US": require('./locales/en_US.json'),
  "zh-CN": require('./locales/zh-CN.json'),
};

class App extends Component {
  state = { initDone: false }

  componentDidMount() {
    emit.on('change_language', lang => this.loadLocales(lang)); // 监听语言改变事件
    this.loadLocales();
  }

  loadLocales(lang = 'zh-CN') {
    // react-intl-universal 是单例模式, 只应该实例化一次
    intl.init({
      currentLocale: lang,
      locales,
    }).then(() => {
      this.setState({ initDone: true });
    });
  }
  render() {
    return (
      this.state.initDone &&
      <div className="App">
        <Router>
          <div>
            {
              routes.map((route, key) => {
                return <Route key={key} exact={route.exact} path={route.path}
                  render={props => (
                    <route.component {...props} routes={route.routes} />
                  )}
                />
              })
            }
          </div>
        </Router>
      </div>
    );
  }
}

//使用connect()()创建并暴露一个Count的容器组件
export default connect(
  state => ({
    count: state.count
  })
)(App)
// export default App;









