/**
 * 判断是否是微信
 * @params null
 * @returns boolean
 */
export const isWeixin = () => {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true
  } else {
    return false
  }
}
